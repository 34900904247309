import { Navbar, Nav, Container } from 'react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import endpoints from '../constants/endpoints';
import ThemeToggler from './ThemeToggler';


/** CSS Styles for the Navbar */
const styles = {
    logoStyle: {
        width: 50,
        height: 40,
    },
};

const ExternalNavLink = styled.a`
  color: ${(props) => props.theme.navbarTheme.linkColor};
  &:hover {
    color: ${(props) => props.theme.navbarTheme.linkHoverColor};
  }
  &::after {
    background-color: ${(props) => props.theme.accentColor};
  }
`;

const InternalNavLink = styled(NavLink)`
  color: ${(props) => props.theme.navbarTheme.linkColor};
  &:hover {
    color: ${(props) => props.theme.navbarTheme.linkHoverColor};
  }
  &::after {
    background-color: ${(props) => props.theme.accentColor};
  }
  &.navbar__link--active {
    color: ${(props) => props.theme.navbarTheme.linkActiveColor};
  }
`;



const NavBar = () => {

    /** Grabs theme and sets initial values for the states */
    const theme = useContext(ThemeContext);
    const [data, setData] = useState(null);
    const [expanded, setExpanded] = useState(false);


    /** Grabs data from the json file for the Navbar when it loads */
    useEffect(() => {
        fetch(endpoints.navbar, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => setData(res))
            .catch((err) => err);
    }, []);


    /** Returns HTML with info about the Navbar and its CSS Styles */
    return (
        <Navbar
            fixed="top"
            expand="md"
            bg="dark"
            variant="dark"
            className="navbar-custom"
            expanded={expanded}
        >
            <Container>
                {data?.logo && (
                    <Navbar.Brand href="/">
                        <img
                            src={data?.logo?.source}
                            className="d-inline-block align-top"
                            alt="main logo"
                            style={
                                data?.logo?.height && data?.logo?.width
                                    ? { height: data?.logo?.height, width: data?.logo?.width }
                                    : styles.logoStyle
                            }
                        />
                    </Navbar.Brand>
                )}
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto" />
                    <Nav>
                        {data
                            && data.sections?.map((section, index) => (section?.type === 'link' ? (
                                <ExternalNavLink
                                    key={section.title}
                                    href={section.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => setExpanded(false)}
                                    className="navbar__link"
                                    theme={theme}
                                >
                                    {section.title}
                                </ExternalNavLink>
                            ) : (
                                <InternalNavLink
                                    key={section.title}
                                    onClick={() => setExpanded(false)}
                                    exact={index === 0}
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to={section.href}
                                    theme={theme}
                                >
                                    {section.title}
                                </InternalNavLink>
                            )))}
                    </Nav>
                    <ThemeToggler
                        onClick={() => setExpanded(false)}
                    />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

const NavBarWithRouter = withRouter(NavBar);
export default NavBarWithRouter;
